import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import betsyjustin3 from "../images/betsyjustin3.jpg";

function AccomodationsPage() {
  return (
    <Layout>
      <SEO
        title="Accommodations"
        keywords={[`accomodations for Betsy and Justin's wedding`]}
      />
      <div class="max-w-full">
        <div class="relative">
          <div class="relative lg:flex overflow-hidden">
            <div class="h-screen lg:h-auto lg:w-6/12 relative flex items-center justify-center">
              <img 
                class="absolute h-full w-full object-cover" 
                src={betsyjustin3}
                alt="Betsy and Justin" 
              />
            </div>
            <div id="accomodations" class="relative lg:w-6/12 bg-white h-full">
              <div class="font-sans-serif relative py-12 lg:py-24 px-8 lg:px-16 text-black leading-relaxed">
                <p class="mb-6 text-2xl">
                  ACCOMODATIONS
                </p>
                <p class="text-gray-900">
                  There are a large number of options for lodging in the downtown Nashville area. Please feel free to book a hotel room or airbnb where you feel most comfortable.
                </p>
                <br></br>
                <p class="text-xs mb-2 font-bold text-black">
                  <b>AirBNB & VRBO</b>
                </p>
                <p>
                  For groups traveling together, we recommend renting a house through a short-term rental service such as, <a href="https://www.airbnb.com" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline">AirBNB</a> or <a href="https://www.vrbo.com" target="_blank" rel="noopener noreferrer" class="text-blue-600 underline">VRBO</a>. There are numerous options available within a three mile radius of the venue.  
                </p>
                <br></br>
                <p class="text-xs mb-2 font-bold text-black">
                  <b>A NOTE</b>
                </p>
                <p>
                  Nashville is a popular tourist destination. We encourage guests to book accommodations as soon as possible.
                </p>
                <br></br>
                <p>
                  If you have any questions regarding accommodations, submit them <a href="mailto:bets.appleton@gmail.com" class="text-blue-600 underline">here</a>.
                </p>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AccomodationsPage;
